import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="w-full min-h-[80vh] bg-white flex items-center justify-center py-16 px-4">
      <div className="max-w-7xl w-full">
        <div className="desktop:flex laptop:flex tablet:flex mobile:flex flex-col items-center justify-center text-center">
          {/* Main Heading */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="desktop:text-6xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-bold text-navy-900 mb-6"
          >
            Professional Solutions
            <span className="block text-[#1a365d]">For Your Business</span>
          </motion.h1>

          {/* Subheading */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="desktop:text-xl laptop:text-lg tablet:text-base mobile:text-sm text-gray-600 mb-8 max-w-2xl"
          >
            Streamline your operations with our comprehensive suite of
            professional services designed to elevate your business performance.
          </motion.p>

          {/* CTA Buttons */}
          <div className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-4">
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="desktop:px-8 laptop:px-8 tablet:px-6 mobile:px-6 
                        desktop:py-4 laptop:py-4 tablet:py-3 mobile:py-3 
                        bg-[#1a365d] text-white rounded-md 
                        hover:bg-[#2a4a7f] transition-colors 
                        font-semibold"
            >
              Get Started
            </motion.button>

            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
              className="desktop:px-8 laptop:px-8 tablet:px-6 mobile:px-6 
                        desktop:py-4 laptop:py-4 tablet:py-3 mobile:py-3 
                        border-2 border-[#1a365d] text-[#1a365d] rounded-md 
                        hover:bg-[#1a365d] hover:text-white transition-colors 
                        font-semibold"
            >
              Learn More
            </motion.button>
          </div>

          {/* Decorative Line */}
          <motion.div
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="w-24 h-1 bg-[#1a365d] mt-16 rounded-full"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
